<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">Quản lí marker config</h1>
    </div>
    <div class="pt-5 pl-5 pr-5">
      <!--      <v-btn rounded color="primary" class="mb-5" x-small large @click="openDiaLogConfig">-->
      <!--        <v-icon color="white" dark>mdi-plus</v-icon><span style="color: white">Thêm config cho giáo viên</span>-->
      <!--      </v-btn>-->
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">User</th>
                    <th scope="col">Weight</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(marker, i) in markerConfigs">
                    <tr :key="i">
                      <td scope="row">{{ i + 1 }}</td>
                      <td class="">
                        <p class="text-dark-75 d-block font-size-lg mb-0">
                          Name: <b>{{ marker.user.name }}</b>
                        </p>
                        <p class="text-dark-75 d-block font-size-lg mb-0">
                          Email: <b>{{ marker.user.email }}</b>
                        </p>
                        <p class="text-dark-75 d-block font-size-lg mb-0">
                          Phone: <b>{{ marker.user.phone }}</b>
                        </p>
                      </td>
                      <td>
                        <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                          style="max-width: 300px"
                        >
                          {{ marker.weight }}
                        </p>
                      </td>
                      <td>
                        <div style="display: flex">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <button
                                class="btn btn-icon btn-light-warning btn-sm"
                                v-bind="attrs"
                                v-on="on"
                                @click="editConfig(i)"
                              >
                                <span
                                  class="svg-icon svg-icon-md svg-icon-primary"
                                >
                                  <v-icon color=""
                                    >mdi-square-edit-outline</v-icon
                                  >
                                </span>
                              </button>
                            </template>
                            <span>Edit</span>
                          </v-tooltip>
                        </div>
                      </td>
                    </tr>
                  </template>
                  <template v-if="!markerConfigs.length">
                    <tr>
                      <td colspan="5" style="text-align: center">No Data</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogConfig" max-width="1400px" scrollable persistent>
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <!--              <span class="headline" v-if="type_form === 'add'">Thêm config</span>-->
              <!--              <span class="headline" v-else>Sửa config</span>-->
              <span class="headline">Cấu hình weight </span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogConfig = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row class="mt-8">
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Giáo viên:</label></v-col
                    >
                    <v-col cols="8" class="ml-4 p-0">
                      <v-autocomplete
                        v-model="user_input"
                        :items="marker_tags"
                        item-text="name"
                        item-value="id"
                        dense
                        filled
                        readonly
                        placeholder="Chọn"
                      ></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right p-0 m-0"
                      ><label class="text-h6">Weight:</label></v-col
                    >
                    <v-col cols="8" class="p-0 m-0 ml-4"
                      ><v-text-field
                        dense
                        v-model="weight_input"
                        type="number"
                        filled
                      ></v-text-field
                    ></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'add'"
              @click="btnAddOrEditConfig(1)"
            >
              Save
            </button>
            <button
              class="btn btn-light-success text-uppercase mr-2"
              v-if="type_form === 'update'"
              @click="btnAddOrEditConfig(2)"
            >
              Save
            </button>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogConfig = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
export default {
  name: "ListMarkerConfig",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  data() {
    return {
      is_call_api: false,
      type_form: null,
      markerConfigs: [],
      marker_tags: [],
      weight_input: null,
      user_input: null,
      dialogConfig: false,
      marker_config_id: null,
      marker_config_index: null,
    };
  },
  async created() {
    this.is_call_api = true;
    await this.getListMarker();
    await this.getAllMarkerConfig();
    this.is_call_api = false;
  },
  methods: {
    getListMarker() {
      let self = this;
      ApiService.get("prep-app/markers").then(function (response) {
        if (response.status === 200) {
          self.marker_tags = response.data;
        }
      });
    },
    async getAllMarkerConfig() {
      let vm = this;
      try {
        vm.is_call_api = true;
        let res = await ApiService.get("prep-app/marker-config");
        if (res.status === 200) {
          vm.markerConfigs = res.data;
          vm.is_call_api = false;
        }
      } catch (error) {
        vm.is_call_api = false;
        console.log(error);
      }
    },
    openDiaLogConfig() {
      this.user_input = null;
      this.weight_input = null;
      this.type_form = "add";
      this.dialogConfig = true;
    },
    validateData() {
      let flat = true;
      if (this.user_input == null) {
        this.$toasted.error("Chưa chọn giáo viên !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (this.weight_input == null || this.weight_input === "") {
        this.$toasted.error("Chưa nhập weight !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }

      // let data_marker_config = JSON.parse(JSON.stringify(this.markerConfigs));
      // if (this.type_form === 'update') data_marker_config.splice(this.marker_config_index, 1)
      // for (let i = 0; i < data_marker_config.length; i++) {
      //   if (this.user_input === data_marker_config[i].user.id) {
      //     this.$toasted.error('Đã cấu hình trọng số cho marker này !!', {theme: "toasted-primary", position: "top-right", duration: 4000});
      //     flat = false;
      //   }
      // }
      return flat;
    },
    btnAddOrEditConfig(type) {
      let validateQues = this.validateData();
      if (!validateQues) {
        return;
      }
      let vm = this;
      let data = {
        user_id: this.user_input,
        weight: this.weight_input,
      };
      vm.is_call_api = true;
      if (type === 1) {
        ApiService.post("prep-app/marker-config", data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Thêm thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.is_call_api = false;
              vm.getAllMarkerConfig();
              vm.dialogConfig = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      } else {
        ApiService.put("prep-app/marker-config/" + vm.marker_config_id, data)
          .then(function (res) {
            if (res.status === 200) {
              vm.$toasted.success("Sửa thành công !!", {
                theme: "toasted-primary",
                position: "top-right",
                duration: 4000,
              });
              vm.getAllMarkerConfig();
              vm.is_call_api = false;
              vm.dialogConfig = false;
            }
          })
          .catch(function (error) {
            vm.is_call_api = false;
            if (error.response.status === 422) {
              let data_response_error =
                error.response.data.error.message.split(".<br />");
              vm.showError(data_response_error);
            }
          });
      }
    },
    editConfig(index) {
      this.marker_config_index = index;
      let data = this.markerConfigs[index];
      this.marker_config_id = data.id;
      this.user_input = data.user.id;
      this.weight_input = data.weight;
      if (data.id == null) {
        this.type_form = "add";
      } else {
        this.type_form = "update";
      }
      this.dialogConfig = true;
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
  },
};
</script>

<style scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
.label.label-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
}
.btn.btn-light-success {
  color: #3699ff;
  background-color: #e1f0ff;
  border-color: transparent;
}
.btn.btn-light-success i {
  color: #3699ff;
}
.btn.btn-light-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-light-success:focus:not(.btn-text),
.btn.btn-light-success.focus:not(.btn-text) {
  background-color: #3699ff;
}
</style>
